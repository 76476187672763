<template>
  <div>
    <b-row no-gutters>
      <b-col cols="12">
        {{ startingTime() }} - {{ endingTime() }}
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      block: {
        type: Object,
        default: this,
      }
    },
    methods: {
      startingTime () {
        return this.$moment.utc(this.block.startAt).tz(this.$store.getters["auth/currentClub"].timezone, true).format('HH:mm');
      },
      endingTime () {
        return this.$moment.utc(this.block.endAt).tz(this.$store.getters["auth/currentClub"].timezone, true).format('HH:mm');
      }
    }
  }
</script>
